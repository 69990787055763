<template>

  <b-card-code
    no-body
    title="Amz Ads Accounts"
  >

    <b-button

      v-if="amazonAdsUri"
      variant="primary"
      size="sm"
      class="btn-tour-finish ml-1 mb-1"
      target="_blank"
      :href="amazonAdsUri"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      <span class="align-middle"> Add new account </span>
    </b-button>

    <b-overlay
      :show="loading"
      rounded="sm"
    >

    <b-alert
          v-if="error"
          variant="danger"
          show
          class="m-2"
        >

          <div class="alert-body">
            <span><strong>Error </strong> {{ error }}</span>

          </div>

        </b-alert>


      <b-table
        striped
        hover
        v-if="accounts"
        :items="accounts"
        :fields="fields"
      >

        <template #cell(id)="data">
          <span v-b-tooltip.hover.top="data.item.id">
            #
          </span>
        </template>

        <template #cell(active)="data">
          <select
            class="form-control"
            @change="onStatusChange($event, data.item.id)"
          >
            <option
              value="true"
              :selected="data.item.active == true"
            >
              Active
            </option>
            <option
              value="false"
              :selected="data.item.active == false"
            >
              Disabled
            </option>
          </select>
        </template>

        <template #cell(logs)="data">

          <router-link :to="{ name: 'amz-ads-logs', params: { accountId: data.item.id }}">
            <b-button

              variant="outline-primary"
              size="sm"
              class="btn-tour-finish mr-1"
            >
              <feather-icon
                icon="ListIcon"
                class="mr-50"
              />
              Logs
            </b-button>
          </router-link>

        </template>

        <template #cell(runAt)="data">
          {{ data.item.runAt }} <b>UTC</b>
        </template>

        <template #cell(createdAt)="data">
          {{ data.item.createdAt|formatDateTime }}
        </template>

        <template #cell(actions)="data">

          <router-link :to="{ name: 'edit-amz-ads-account', params: { accountId: data.item.id }}">
            <b-button
              variant="primary"
              size="sm"
              class="btn-tour-finish mr-1"
            >
              Edit

            </b-button>

          </router-link>

          <a
            class="text-danger"
            @click="remove(data.item.id)"
          >Remove</a>

        </template>

      </b-table>

      <div
        v-if="rows > 25"
        class="mt-1 d-flex justify-content-center"
      >
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
          @change="handlePageChange"
        />
      </div>

    </b-overlay>

  </b-card-code>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BPagination, VBTooltip, BOverlay, BAlert
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BAlert,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    ToastificationContent,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      amazonAdsUri: null,
      currentPage: 1,
      error:null,
      accounts: [],
      perPage: 25,
      fields: ['id', 'name', 'accountId', 'logs', 'active', 'runAt', 'actions'],
      current: 1,
      rows: 0,
    }
  },

  mounted() {
    this.loadAccounts()
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadAccounts(this.page)
    },

    getAmazonUri() {
      this.$store.dispatch('amzSellingPartnerAccount/getState')
        .then(result => {
          this.amazonAdsUri = `https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.fa1e698b22114fb78e175f831f6282fc&scope=advertising::campaign_management&response_type=code&redirect_uri=https://creativva.com/api/v1/amz-ads/amz-redirect&state=${
            result.data.value}`
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading amazonUri ', 'danger')
          
        })
    },

    loadAccounts(page = 1) {
      this.loading = true
      this.$store.dispatch('amzAds/fetchAll', page)
        .then(result => {
          this.accounts = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
          this.loading = false
          this.getAmazonUri()
        },
        error => {
          this.showToast('Error', error?.data?.message || error?.message || 'Error while loading items', 'danger')
          this.error = error?.data?.message || error?.message || 'Error while loading items'
        }).finally(() => {
          this.loading = false
        })
    },

    remove(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.$store.dispatch('amzAds/remove', id).then(
            () => {
              this.showToast('Success', 'Removed with success', 'success')
              this.loadAccounts()
            },
            error => {
              console.log(err)
              this.showToast('Error', error.response?.data?.message || error?.message || 'Error while removing item', 'danger')
            },
          )
        }
      },
      error => {
        console.log(err)
      })
    },

    onStatusChange($event, id) {
      const data = {
        id,
        active: event.target.value,
      }

      this.$store.dispatch('amzAds/update', data)
        .then(result => {
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>
